<template>
  <div class="ProHistoryEdit">
    <div class="pageTitle">历史估值维护</div>
    <div class="content">
      <div class="tableBox">
        <el-table
          ref="eltable"
          :data="tableData"
          :header-cell-style="{background:'#f2f2f2'}"
          height="100%"
          stripe
          border
          v-loading="tableLoading"
        >
          <template slot="empty">
            <img src="@/assets/img/table_noData.png" width="300" height="300" />
          </template>
          <el-table-column type="index" label="序号" align="center" width="50px"></el-table-column>
          <el-table-column prop="assessDate" label="日期" align="center"></el-table-column>
          <el-table-column prop="assessMonth" label="月份归属" align="center"></el-table-column>
          <el-table-column prop="week" label="周归属" align="center"></el-table-column>
          <el-table-column prop="amount" label="估值" align="center"></el-table-column>
          <el-table-column prop="sysUserName" label="操作人" align="center"></el-table-column>
          <el-table-column label="操作" align="center" fixed="right" width="100">
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="delHandle(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
        style="margin-top: 10px;"
        background
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
      </div>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-plus"
        @click="addHistory"
      >添加历史估值
      </el-button>
    </div>
    <!-- 设置题目 -->
    <add-pro-history
      ref="AddProHistory"
      @refreshTable="refreshTable"
    ></add-pro-history>
  </div>
</template>

<script>
import AddProHistory from './ProHistoryEdit/AddProHistory.vue';
import api from '@/api/ProHistoryApi';

export default {
  name: 'ProHistoryEdit',
  components: { AddProHistory },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 300,
      },
    };
  },
  created() {
    document.title = '历史估值';
    const modelId = this.$route.params.id;
    this.reqTableData(modelId);
  },
  methods: {
    delHandle(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            api.modelassessmentdelete(
              {
                id: row.id,
              },
            )
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable(this.$route.params.id);
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    addHistory() {
      this.$refs.AddProHistory.dialogVisible = true;
      this.$refs.AddProHistory.dialogTitle = '添加估值历史';
    },
    refreshTable(modelId) {
      this.reqTableData(modelId);
    },
    reqTableData(modelId) {
      this.tableLoading = true;

      const {
        pageNum,
        pageSize,
      } = this.pageInfo;
      api.modelassessmentlist(
        {
          modelId,
          pageNum,
          pageSize,
        },
      )
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSelectionChange(select) {
      this.selectedTableData = select;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .ProHistoryEdit {
    .pageTitle {
      position: fixed;
      top: 0;
      background: #ffffff;
      color: #333;
      padding: 0 20px;
      line-height: 50px;
      width: 100%;
      box-shadow: 0 0px 10px 0px rgba(0,0,0,0.1);
    }
    .content {
      padding: 50px;
      max-width: 1200px;
      margin: auto;
      .tableBox {
        height: calc(100vh - 300px);
        padding: 0;
        margin: 50px auto 20px;
      }
    }
  }
</style>
