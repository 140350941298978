<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="日期"
              prop="assessDate"
            >
              <el-date-picker
                v-model="formData.assessDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                @change="monthWeekCalc"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="月份归属"
              prop="assessMonth"
            >
              <el-input disabled
                placeholder="自动计算"
                v-model="formData.assessMonth"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="周归属"
              prop="week"
            >
              <el-input disabled
                placeholder="自动计算"
                v-model="formData.week"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="估值"
              prop="amount"
            >
              <el-input
                placeholder="请输入"
                v-model.trim="formData.amount"
                @input="numberCtrol(formData, 'amount', 2, 20)"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { numberCtrol } from '@/utils/utils';
import api from '@/api/ProHistoryApi';

export default {
  name: 'AddProHistory',
  data() {
    return {
      assessDate: '',
      assessMonth: '',
      week: '',
      dialogVisible: false,
      dialogTitle: '添加估值参数配置',
      loading: false,
      formData: {
        assessDate: '',
        assessMonth: '',
        week: '',
        amount: '',
      },
      rules: {
        assessDate: [{ required: true, message: '请选择', trigger: 'change' }],
        amount: [{ required: true, message: '请输入', trigger: 'change' }],
      },
    };
  },
  computed: {

  },
  watch: {},
  methods: {
    monthWeekCalc(date) {
      console.log(date);
      this.formData.assessMonth = date.substring(0, 7);
      this.formData.week = this.getWeek(date);
    },
    getWeek(date) {
      const dateTmp = new Date(date);
      let w = dateTmp.getDay();
      const d = dateTmp.getDate();
      if (w === 0) {
        w = 7;
      }
      return Math.ceil((d + 6 - w) / 7);
    },
    numberCtrol,
    submitForm() {
      console.log(this.formData);
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            assessDate,
            assessMonth,
            week,
            amount,
          } = this.formData;
          const modelId = this.$route.params.id;
          const sysUserId = this.$store.state.login.userInfo.userId;
          console.log(modelId);
          api.modelassessmentadd(
            {
              modelId,
              assessDate,
              assessMonth,
              week,
              amount,
              sysUserId,
            },
          )
            .then(() => {
              this.dialogVisible = false;
              this.$emit('refreshTable', modelId);
            })
            .catch(err => {
              console.log(err);
              this.$message.error(err.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
